import { IApiResponseMap, HttpStatus, IGenericApiError } from "src/services/client";
import { Service } from "src/services/service";
import { IUserRead, IOrganizations } from "src/services/services/";
import { ICommunityRead } from "src/services/services/community-service";
import { IPaginatedResponseV1 } from "src/types/paginated-response";

export enum GuestType {
    Order = "order",
    Host = "host",
    Booking = "booking",
}

export interface IGuestBase {
    uuid: string;
    email: string;
    activates: string;
    expires: string;
    comment: string;
    token: string;
    host: string | IUserRead;
    hostGroup: string;
    community: string | ICommunityRead;
    sourceType: GuestType;
    creator: string | IUserRead;
    organization: string | IOrganizations | null;
    sent: null | string;
    sourceUuid: string;
    created: string;
    updated: string;
}

export type IGuestRead = Expand<IGuestBase, "host">;
export type IGuestDetailsRead = Expand<IGuestBase, "host" | "creator" | "community">;

export class GuestsService extends Service {
    public list(params?: IQueryParams) {
        params = { ...params, expand: "host" };
        const url = this.client.url(["api", "v1", "guests"], params, {
            applyTagFilter: true,
        });

        return this.client.get<
            IApiResponseMap<{
                [HttpStatus.Ok]: IPaginatedResponseV1<IGuestRead>;
            }>
        >(url);
    }

    public async retrieve(id: string) {
        const params = { expand: ["host", "creator", "community"] };
        const url = this.client.url(["api", "v1", "guests", id], params);

        return this.client.get<
            IApiResponseMap<{
                [HttpStatus.Ok]: IGuestDetailsRead;
                [HttpStatus.NotFound]: IGenericApiError;
            }>
        >(url);
    }

    public async resendGuestInvitation(id: string) {
        const url = this.client.url(["api", "v1", "guests", id, "resend-invitation"]);

        return this.client.post<
            IApiResponseMap<{
                [HttpStatus.Created]: IGuestRead;
                [HttpStatus.NotFound]: IGenericApiError;
            }>
        >(url, {});
    }

    public async delete(id: string) {
        const url = this.client.url(["api", "v1", "guests", id]);

        return this.client.delete<
            IApiResponseMap<{
                [HttpStatus.NoContent]: never;
                [HttpStatus.NotFound]: IGenericApiError;
            }>
        >(url);
    }
}
